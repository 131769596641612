import { INPUT_TYPE } from "constants/enums/input-type"
import { DefaultPreviewComponent } from "./Default"
import { RelationPreviewComponent } from "./Relation"
import { valueType } from "../CustomField"
import { CheckboxPreviewComponent } from "./Checkbox"
import { CurrencyPreviewComponent } from "./Currency"
import { DateTimePreviewField } from "./DateTimePreviewField"

type PreviewType = "default" | keyof typeof INPUT_TYPE

interface PreviewFieldProps {
  previewType?: PreviewType
  previewProps: BasePreviewProps
}

export interface BasePreviewProps {
  label: string
  value?: valueType
  component: string
  unit?: string
  requester?: any
}

const MapTypeToPreview = (type: PreviewType, props: BasePreviewProps) => {
  switch (type) {
    case INPUT_TYPE.RELATION:
      return <RelationPreviewComponent {...props} />
    case INPUT_TYPE.CHECKBOX:
      return <CheckboxPreviewComponent {...props} />
    case INPUT_TYPE.NUMBER:
    case INPUT_TYPE.CURRENCY:
      return <CurrencyPreviewComponent {...props} />
    case INPUT_TYPE.DATE:
    case INPUT_TYPE.DATE_TIME:
      return <DateTimePreviewField {...props} />
    default:
      return <DefaultPreviewComponent {...props} />
  }
}

export const PreviewField = (props: PreviewFieldProps) => {
  const { previewType = "default", previewProps } = props
  return <>{MapTypeToPreview(previewType, previewProps)}</>
}
