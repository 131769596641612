import { gql } from "@apollo/client"
import { ClaimRequestHistoryItem } from "components/advance/ClaimRequestHistoryItem"
import { Box, Typography } from "components/common"
import Loader from "components/Loader"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { compose, withHooks, withStores } from "enhancers"
import { every, isEmpty } from "lodash"
import InfiniteScroll from "react-infinite-scroll-component"
import paths from "routes/paths"
import styled from "styled-components"

const ContentContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`

const Contents = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const HistoryTab = (props: any) => (
  <>
    {props.loading && <Loader />}
    {props.showEmptyPage && (
      <ContentContainer>
        <Contents>
          <Typography variant="h3" color="Text/Primary Text">
            ไม่มีรายการขอเบิก
          </Typography>
          <Typography variant="body1" color="Text/Gray Preview" style={{ fontSize: "16px", marginTop: "17px" }}>
            ไม่พบข้อมูลการขอเบิกในสถานะนี้
          </Typography>
        </Contents>
      </ContentContainer>
    )}
    {props.showNotFoundSearch ? (
      <Box>
        <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography variant="h3" color="Gray/Secondary Text">
            รายการค้นหา
          </Typography>
          <Typography variant="subtitle2" color="Gray/Primary Text">
            (0 รายการ)
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="fixed"
          top="40%"
          left="10%"
          right="10%"
        >
          <Typography variant="h3" color="Gray/Secondary Text">
            ไม่พบรายการที่ต้องการ
          </Typography>
          <Typography variant="h4" color="Text/Placeholder">
            กรุณาตรวจสอบข้อมูลตัวกรองที่ใช้ค้นหาอีกครั้ง
          </Typography>
        </Box>
      </Box>
    ) : (
      <InfiniteScroll
        dataLength={props.claimRequestHistory.length ?? 0}
        next={props.fetchMoreData}
        hasMore={props.hasMore}
        loader={<Loader />}
        height={props.height}
      >
        {props.claimRequestHistory.map((history: any) => (
          <ClaimRequestHistoryItem
            key={history.id}
            id={history.id}
            info={history.info}
            workflowSeq={history.workflowSeq}
            department={history.employee.department}
            createdAt={history.createdAt}
            referenceId={history.referenceId}
            amount={props.requestAmount(history)}
            onClick={(id: string) => props.handleClickHistoryItem(id)}
            owner={history.employee}
            createdBy={history.createdBy}
            userRole={props.currentUser.role}
            status={history.status}
            workflowApproved={history.workflowApproved}
            totalWorkflow={history.totalWorkflow}
            myApprovalStatus={history.myApprovalStatus}
          />
        ))}
      </InfiniteScroll>
    )}
  </>
)

const API = {
  GET_CLAIM_REQUEST_HISTORY: gql`
    query GET_CLAIM_REQUEST_HISTORY($employeeId: String!, $status: String, $filters: JSON, $pagination: JSON) {
      claimRequestHistory(
        input: { employeeId: $employeeId, status: $status, filters: $filters, pagination: $pagination }
      ) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        createdBy
        referenceId

        workflowApproved
        totalWorkflow
        myApprovalStatus
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { externalCanSubmit, currentUserInfo, windowHeight, status } = props
    const { useQuery, useCallback, useEffect, useMemo, useState, useUrlParam } = hooks

    const queryParams = useUrlParam()
    const { filters = {} } = queryParams

    const statusTab = useMemo(() => {
      return status
    }, [status])

    const currentUser = useMemo(() => {
      return currentUserInfo?.employee
    }, [currentUserInfo])

    const canSubmit = useMemo(() => {
      return externalCanSubmit
    }, [externalCanSubmit])

    const [hasFilter, setHasFilter] = useState(false)
    const [hasMore, setHasMore] = useState(true)

    const { data, refetch, fetchMore, loading, error } = useQuery(API.GET_CLAIM_REQUEST_HISTORY, {
      variables: {
        status: statusTab,
        employeeId: currentUser?.id || "",
        pagination: { offset: 0, limit: 10 },
        filters: filters,
      },
      onCompleted: (data: any) => {
        if (data?.claimRequestHistory.length === 0) {
          setHasMore(false)
        }
      },
      fetchPolicy: "cache-and-network",
    })

    const historyData = useMemo(() => {
      if (error || loading) {
        return []
      }

      return data?.claimRequestHistory || []
    }, [error, loading, data?.claimRequestHistory])

    const refetchClaimList = useCallback(async () => {
      if (currentUser) {
        await refetch({
          variables: {
            status: statusTab,
            employeeId: currentUser?.id,
            pagination: { offset: 0, limit: 10 },
            filters: filters,
          },
        })
        const filterValues = filters
        if (!isEmpty(filterValues)) {
          setHasFilter(true)
        }
        if (every(filterValues, (value) => !value)) {
          setHasFilter(false)
        }
      }
    }, [currentUser, filters, refetch, statusTab])

    const showNotFoundSearch = useMemo(() => historyData.length === 0 && hasFilter, [hasFilter, historyData.length])

    const fetchMoreData = useCallback(() => {
      fetchMore({
        variables: {
          status: statusTab,
          filters: filters,
          employeeId: currentUser?.id,
          pagination: {
            offset: historyData.length,
            limit: 10,
          },
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (fetchMoreResult.claimRequestHistory) setHasMore(fetchMoreResult.claimRequestHistory.length > 0)
          if (!fetchMoreResult) return prev
          return {
            claimRequestHistory: [...prev.claimRequestHistory, ...fetchMoreResult.claimRequestHistory],
          }
        },
      })
    }, [fetchMore, statusTab, filters, currentUser, historyData.length])

    const requestAmount = useCallback((history: any) => {
      return history?.info?.values?.inputs[history.info.amountFieldName] || 0
    }, [])

    const handleClickHistoryItem = useCallback(
      (id: string) => {
        if (statusTab === EnumClaimRequestStatus.DRAFT) {
          paths.editDraftRequestPath(id, { tab: EnumClaimRequestStatus.DRAFT, filters: filters }).push()
        } else {
          paths.historyDetailPath(id, { tab: statusTab, filters: filters }).push()
        }
      },
      [statusTab, filters],
    )

    useEffect(() => {
      if (canSubmit) {
        const fetchData = () => {
          refetchClaimList()
        }
        fetchData()
      }
    }, [canSubmit, refetchClaimList])

    const showEmptyPage = useMemo(() => {
      return historyData.length === 0 && !hasMore && !hasFilter && !loading
    }, [historyData, hasMore, hasFilter, loading])

    return {
      hasMore,
      hasFilter,

      claimRequestHistory: historyData,
      requestAmount,
      handleClickHistoryItem,
      currentUser,

      fetchMoreData,
      showNotFoundSearch,
      height: windowHeight,

      loading,
      showEmptyPage,
    }
  }),
)

const ClaimHistoryTab = enhancer(HistoryTab)

export default ClaimHistoryTab
